import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  darkTheme: false,
}

export const ThemeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setTheme: (state, action) => {
      const { darkTheme } = action.payload;
      state.darkTheme = darkTheme;
    }
  }
})

export const {setTheme} = ThemeSlice.actions;

export default ThemeSlice.reducer;
