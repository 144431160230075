import React, { useContext } from "react";
import style from "./SharedBtn.module.css";
import { useSelector } from "react-redux";
import { MouseContext } from "../../context/MouseContext";

const SharedBtn = ({
  onClick = () => { },
  className = "",
  btnStyle = {},
  type = "",
  children,
  useType = "fill",
}) => {
  const { darkTheme } = useSelector((state) => state.theme);
  const ctx = useContext(MouseContext);

  return (
    <button
      typeof={type}
      onMouseEnter={() => ctx.cursorChangeHandler("cursor")}
      onMouseLeave={() => ctx.cursorChangeHandler("")}
      style={{
        ...btnStyle,
        color: useType !== "fill" ? (darkTheme ? "white" : "") : "",
        borderColor: useType !== "fill" ? (darkTheme ? "white" : "") : "",
        // boxShadow:
        //   useType !== "fill"
        //     ? darkTheme
        //       ? "0px 4px 0px 0px #ffffff"
        //       : ""
        //     : "",
      }}
      type={type}
      onClick={onClick}
      className={`${useType === "fill" ? style.btn : style.btn2} ${useType !== "fill" ? (darkTheme ? style.shadow : "") : ""
        } ${className} cursor`}
    >
      {children}
    </button>
  );
};

export default SharedBtn;
