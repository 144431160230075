/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState } from "react";
import education from "../../constants/education";
import experience from "../../constants/experience";
import SharedBtn from "../../HelperComponents/SharedBtn/SharedBtn";
import style from "./About.module.css";
import image from "./Assets/image.png";
import { HiOutlineMail } from "react-icons/hi";
import { BsArrowUpRight } from "react-icons/bs";
import { useSelector } from "react-redux";
import image1 from "../../Assets/img1.jpg";
import image2 from "../../Assets/img2.jpg";
import image3 from "../../Assets/img3.jpg";
import achievements from "../../constants/achievements";

const About = () => {
  const { darkTheme } = useSelector((state) => state.theme);
  const [id, setId] = useState(3);
  const [imgClass, setImgClass] = useState(null);

  const imageArr = [image, image1, image2, image3];

  const handleClick = () => {
    fetch("resume.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Shivam Jayaswal Verified Design Resume.pdf";
        alink.click();
      });
    });
  };

  const handleChangeImage = () => {
    if (id === imageArr.length - 1) {
      setId(0);
    } else {
      setId(id + 1);
    }
  };

  useEffect(() => {
    setImgClass(style.imgClass);
    setTimeout(() => setImgClass(null), 150);
  }, [id]);

  return (
    <div className={style.structure}>
      <div className={style.intro}>
        <h1>What’s my Story?</h1>
        <p
          style={{
            color: darkTheme ? "white" : "",
          }}
        >
          Glad you asked
        </p>
        <div
          className={style.hashTag}
          style={{
            color: darkTheme ? "white" : "",
          }}
        >
          <span>#Cheesy</span>
          <span>#Interesting</span>
          <span>#Originstory</span>
        </div>
      </div>
      <div
        className={style.description}
        style={{ color: darkTheme ? "white" : "" }}
      >
        <p>
          Hello again! My name is Shivam Jayaswal. I am a final-year
          undergraduate at IIT Roorkee. In the past, I've worked with companies
          such as Fampay, Rephrase.ai, and Cult.fit.
          <br />
          <br />
          Initially an Architecture Undergrad, I cultivated a fascination for
          buildings and products. Gaining eventual interest in UX design, I got
          obsessed with the 'whys' behind the products we use. Since then, I
          have channeled all my energy into creating experiences that people
          love.
          <br />
          <br />
          Beyond work, I am an avid enthusiast of hip-hop, gaming, and
          traveling. Trying out various art styles for my art & funky
          illustrations or going on weekend drives are a few things I enjoy.
          <br />
          <br />I am a designer experienced in multiple domains, including
          FinTech, AI, and Wellness, who can see a project from research &
          design to prototyping and ensure a smooth developer handoff.
        </p>
      </div>
      <div className={`${style.image} cursor`}>
        <p style={{ color: darkTheme ? "white" : "" }}>// Click on me //</p>
        <div
          className={style.im}
          style={{
            backgroundImage:
              darkTheme &&
              `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='white' stroke-width='2' stroke-dasharray='6%2c6' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e")`,
            // border: darkTheme ? "1px dashed white" : "1px dashed black",
          }}
        ></div>
        <img
          src={imageArr[id]}
          alt=""
          className={`${style.imgBlack} ${imgClass}`}
          style={{ borderRadius: 2 }}
          onClick={handleChangeImage}
        />
      </div>
      <div className={style.descriptionExp}>
        <h2 style={{ color: darkTheme ? "white" : "" }}>Experience</h2>
        <div className={style.details}>
          {experience.map((data, idx) => {
            return (
              <div className={style.detailData}>
                <h3 style={{ color: darkTheme ? "white" : "" }}>
                  {data.position}, {data.company}
                </h3>
                <p
                  style={{ color: darkTheme ? "white" : "" }}
                  className={style.location}
                >
                  {data.duration} / {data.location}
                </p>
                <span
                  style={{
                    color: darkTheme ? "white" : "",
                    opacity: darkTheme ? "0.5" : "",
                  }}
                >
                  {data.description}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={style.line}
        style={{ borderColor: darkTheme ? "rgba(255, 255, 255, 0.1)" : "", marginBottom: 56 }}
      ></div>
      <div className={`${style.descriptionExp} ${style.margin}`}>
        <h2>Achievements & Mentions</h2>
        <div className={style.details}>
          {achievements.map((data, idx) => {
            return (
              <div className={style.detailData}>
                <h3
                  style={{
                    color: darkTheme ? "white" : "",
                  }}
                >
                  {data.position}, {data.company}
                </h3>
                <p
                  style={{
                    color: darkTheme ? "white" : "",
                  }}
                  className={style.location}
                >
                  {data.duration} / {data.location}
                </p>
                <span
                  style={{
                    color: darkTheme ? "white" : "",
                    opacity: darkTheme ? "0.5" : "",
                  }}
                >
                  {data.description}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={style.line}
        style={{ borderColor: darkTheme ? "rgba(255, 255, 255, 0.1)" : "", marginBottom: 56 }}
      ></div>
      <div className={`${style.descriptionExp} ${style.margin}`}>
        <h2>Education</h2>
        <div className={style.details}>
          {education.map((data, idx) => {
            return (
              <div className={style.detailData}>
                <h3
                  style={{
                    color: darkTheme ? "white" : "",
                  }}
                >
                  {data.position}, {data.company}
                </h3>
                <p
                  style={{
                    color: darkTheme ? "white" : "",
                  }}
                  className={style.location}
                >
                  {data.duration} / {data.location}
                </p>
                <span
                  style={{
                    color: darkTheme ? "white" : "",
                    opacity: darkTheme ? "0.5" : "",
                  }}
                >
                  {data.description}
                </span>
              </div>
            );
          })}
          <div className={style.btn}>
            <SharedBtn
              className={style.btn2}
              useType="shallow"
              onClick={handleClick}
            >
              Download Resume <HiOutlineMail size={18} className={style.btnIcon} />
            </SharedBtn>
            {/* <SharedBtn className={style.btn2} useType={"shallow"}>
              More Work <BsArrowUpRight className={style.btnIcon} />
            </SharedBtn> */}
            <SharedBtn
              useType={"shallow"}
              className={style.btn1}
              onClick={handleClick}
            >
              Download Resume
            </SharedBtn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
