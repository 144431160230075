import google from "../Assets/google.png";
import sample from "../Assets/sample.png";
import sbi from "../Assets/sbi.png";
import park from "../Assets/park.png";
import snapchat from "../Assets/snapchat.png";

const portfolio = [
  {
    name: "File Management Experience in Google Drive",
    title: "File Management Experience in Google Drive",
    description:
      "Enhancing File management experience in Google Drive for businesses/workspaces.",
    link: "https://bootcamp.uxdesign.cc/the-file-management-experience-in-google-drive-ux-design-sprint-5741d4e16dca",
    projectImage: google,
  },
  {
    name: "Rephrase.ai - AI Video Generation",
    title: "Rephrase.ai - AI Video Generation",
    description:
      "Designing a web tool, allowing mass creation of AI Generated Videos, just by typing-in script.",
    link: "https://www.rephrase.ai/",
    projectImage: sample,
  },
  {
    name: "SBI Mutual Funds Redesign",
    title: "SBI Mutual Funds Redesign",
    description:
      "Introducing Mutual funds to the SBI YONO app, and making the SBI MF Fund page more accessible.",
    link: "https://www.notion.so/saladdesign/SBI-MF-Scheme-Page-Website-YONO-App-62e17e82e7f2455c85d71daab528e2fc",
    projectImage: sbi,
  },
  {
    name: "Park.it - UX Design Challenge",
    title: "Park.it - UX Design Challenge",
    description:
      "Designing an App that allows users to book slots in Multistorey Automated Parkings near them.",
    link: "https://bootcamp.uxdesign.cc/park-it-ux-design-challenge-2f3ed1c0c3b4",
    projectImage: park,
  },
  {
    name: "Introducing SnapChat Payments",
    title: "Introducing SnapChat Payments",
    description:
      "A 3 day design sprint to introduce Non-UPI, Peer to Peer payments in the core SnapChat Experience.",
    link: "https://www.notion.so/saladdesign/Digital-Payments-in-SnapChat-SnapPay-e71c9792e42640a6946857a858ec47cc",
    projectImage: snapchat,
  },
];

export default portfolio;
