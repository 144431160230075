import React from "react";
import { useSelector } from "react-redux";
import style from "./SharedProject.module.css";

const SharedProject = ({ shortTxt, src, title, desicription, link }) => {
  const { darkTheme } = useSelector((state) => state.theme);

  return (
    <div className={style.container}>
      <img
        onClick={() => window.open(link)}
        className={`cursor ${!darkTheme ? style.imgBlack : style.imgWhite}`}
        src={src}
        alt=""
      />
      <h2>{title}</h2>
      <p
        style={{
          width: shortTxt ? "60%" : "",
          color: darkTheme ? "rgba(255, 255, 255, 0.75)" : "",
        }}
      >
        {desicription}
      </p>
    </div>
  );
};

export default SharedProject;
