import React from "react";
import style from "./LandingPage.module.css";
import { IoLocationSharp } from "react-icons/io5";
import Projects from "./Projects/Projects";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MouseContext } from "../../context/MouseContext";
import com1 from "../../Assets/fampay.png";
import com2 from "../../Assets/cult.png";
import com3 from "../../Assets/c.png";
import c1 from "../../Assets/c1.png";
import google from "../../Assets/google.png";
import portfolio from "../../constants/portfolio";
import SharedProject from "../../HelperComponents/SharedProject/SharedProject";

const LandingPage = () => {
  const { darkTheme } = useSelector((state) => state.theme);
  const navigate = useNavigate();
  const ctx = useContext(MouseContext);

  return (
    <div className={style.structure}>
      <div className={style.location}>
        {/* <IoLocationSharp size={20} /> */}
        <h2 style={{ color: darkTheme ? "white" : "" }}>Hey, I'm</h2>
      </div>
      <div
        className={style.heading}
        style={{
          color: darkTheme ? "white" : "",
          backgroundColor: darkTheme ? "rgba(255, 242, 198, 0.12)" : "",
        }}
      >
        Shivam Jayaswal
      </div>
      <div className={style.content}>
        On a mission to rid this world of bad experiences. Apart from saying
        cheesy one liners, I love to create art and <a href="https://saladdoodles.framer.photos/" rel="noreferrer" target="_blank">doodle</a> random
        stuff.
      </div>
      <div className={style.content}>
        Highly productive teams I’ve worked with <br />
        <div className={style.companies}>
          <img
            style={{
              filter: darkTheme ? "grayscale(0)" : "",
              opacity: darkTheme ? "1" : "",
            }}
            onClick={() => window.open("https://fampay.in/")}
            src={com1}
            alt=""
          />
          <img
            style={{
              filter: darkTheme ? "grayscale(0)" : "",
              opacity: darkTheme ? "1" : "",
            }}
            onClick={() => window.open("https://www.cult.fit/")}
            src={darkTheme ? c1 : com2}
            alt=""
          />
          <img
            style={{
              filter: darkTheme ? "grayscale(0)" : "",
              opacity: darkTheme ? "1" : "",
            }}
            src={com3}
            onClick={() => window.open("https://www.rephrase.ai/")}
            alt=""
          />
        </div>
      </div>
      {/* <div
        className={`${style.link} cursor`}
        style={{ color: darkTheme ? "white" : "" }}
        onMouseEnter={() => ctx.cursorChangeHandler("cursor")}
        onMouseLeave={() => ctx.cursorChangeHandler("")}
      >
        <span onClick={() => navigate("/about")}>More about me</span>
        <hr
          style={{
            width: "100%",
            height: 0.1,
            border: "0.25px solid #282828",
            backgroundColor: "#282828",
            opacity: 0.6,
          }}
        />
      </div> */}
      <div
        style={
          {
            // width: "80%",
            // display: "flex",
            // justifyContent: "center",
            // marginBottom: "72px",
          }
        }
        className={style.portfolio1}
      >
        <SharedProject
          src={portfolio[0].projectImage}
          title={portfolio[0].title}
          shortTxt
          link={portfolio[0].link}
          desicription={portfolio[0].description}
        />
      </div>
      <div className={style.projects}>
        {portfolio.map((data, idx) => {
          return idx === 0 ? (
            <></>
          ) : (
            <SharedProject
              link={data.link}
              src={data.projectImage}
              title={data.title}
              desicription={data.description}
            />
          );
        })}
      </div>
      {/* <Projects /> */}
    </div>
  );
};

export default LandingPage;
