import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./Components/About/About";
import LandingPage from "./Components/LandingPage/LandingPage";
import Header from "./HelperComponents/Header/Header";
import Loader from "./Assets/Loader.mp4";
import { useRef } from "react";

function App() {
  const { darkTheme } = useSelector((state) => state.theme);
  const [loading, setLoading] = useState(true);
  const videoRef = useRef(null);
  const [animation, setAnimation] = useState("");

  useEffect(() => {
    window.location.replace("https://saladdesign.framer.website/");
  }, [])

  useEffect(() => {
    if (darkTheme) {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }
  }, [darkTheme]);

  const attemptPlay = () => {
    videoRef &&
      videoRef.current &&
      videoRef.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  window.addEventListener("load", () => {
    setTimeout(() => {
      setLoading(false);
    }, 1350);
  });

  // useEffect(() => {
  //   attemptPlay();
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 1350);
  //   setTimeout(() => {
  //     setAnimation("logo");
  //   }, 1250);
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  return loading ? (
    <div
      style={{
        width: "100%",
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className={animation}
    >
      {/* <video
        style={{ width: "120px", height: "120px" }}
        muted
        // playbackRate={1.5}
        src={Loader}
        ref={videoRef}
        autoPlay={true}
        loop={true}
      /> */}
    </div>
  ) : (
    <Router>
      <Routes>
        <Route element={<Header />} path="/">
          <Route element={<LandingPage />} index />
          <Route element={<About />} path="/about" />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
