import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import style from "./Header.module.css";
import { BiSun } from "react-icons/bi";
import { FiMusic } from "react-icons/fi";
import sign from "./Assets/logo.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../../Store/themeSlice";
import logoW from "./Assets/logo-white.svg";
import { HiOutlineMoon } from "react-icons/hi";
import Cursor from "../../Components/Cursor/Cursor";
import { useContext } from "react";
import { MouseContext } from "../../context/MouseContext";
import { useState } from "react";

const Header = () => {
  const dispatch = useDispatch();
  const { darkTheme } = useSelector((state) => state.theme);
  const navigate = useNavigate();
  const ctx = useContext(MouseContext);
  const [open, setOpen] = useState(false);

  const handleThemeChange = () => {
    dispatch(setTheme({ darkTheme: !darkTheme }));
  };

  return (
    <div className={style.content}>
      <div
        className={style.backdrop}
        style={{ display: !open ? "none" : "" }}
      ></div>
      {/* <Cursor /> */}
      <div
        className={style.header}
        style={{
          border: open ? "none" : "",
          backgroundColor: darkTheme ? "#121212" : "",
        }}
      >
        <div className={style.left}>
          <div
            className={`${style.icon} cursor`}
            onClick={handleThemeChange}
            onMouseEnter={() => ctx.cursorChangeHandler("cursor")}
            onMouseLeave={() => ctx.cursorChangeHandler("")}
          >
            {!darkTheme ? (
              <BiSun
                className="cursor"
                size={20}
                // style={{ marginRight: "12px" }}
              />
            ) : (
              <HiOutlineMoon
                // onClick={handleThemeChange}
                className="cursor"
                size={20}
                // style={{ marginRight: "12px" }}
                color={"#ffffff"}
              />
            )}
          </div>
          <div
            className={`${style.icon} cursor`}
            onMouseEnter={() => ctx.cursorChangeHandler("cursor")}
            onMouseLeave={() => ctx.cursorChangeHandler("")}
          >
            <FiMusic
              className="cursor"
              size={20}
              color={darkTheme ? "#FFFFFF" : ""}
            />
          </div>
        </div>
        <div
          className={`${style.middle} cursor`}
          onMouseEnter={() => ctx.cursorChangeHandler("cursor")}
          onMouseLeave={() => ctx.cursorChangeHandler("")}
          style={{ color: `${darkTheme ? "#FFFFFF" : ""}` }}
        >
          <div
            className={style.headerTitle}
            onClick={() => navigate("/")}
            style={{ visiblity: open ? "hidden" : "inherit" }}
          >
            <img src={darkTheme ? logoW : sign} alt="" />
            SALAD DESIGN
          </div>
          <div className={style.hamburger} onClick={() => setOpen(!open)}>
            <div
              className={style.line}
              style={{
                transform: open
                  ? "rotateZ(45deg) translateY(5px) translateX(5.5px)"
                  : "",
                borderColor: darkTheme ? "white" : "",
              }}
            ></div>
            <div
              className={style.line}
              style={{
                display: open ? "none" : "",
                borderColor: darkTheme ? "white" : "",
              }}
            ></div>
            <div
              className={style.line}
              style={{
                transform: open
                  ? "rotateZ(-45deg) translateY(-5px) translateX(5px)"
                  : "",
                borderColor: darkTheme ? "white" : "",
              }}
            ></div>
          </div>
        </div>
        <div
          className={style.right}
          style={{ color: `${darkTheme ? "rgba(255, 255, 255, 0.5)" : ""}` }}
        >
          <Link
            onMouseEnter={() => ctx.cursorChangeHandler("cursor")}
            onMouseLeave={() => ctx.cursorChangeHandler("")}
            style={{ color: `${darkTheme ? "rgba(255, 255, 255, 0.5)" : ""}` }}
            to="/about"
          >
            About
          </Link>
          <span>/</span>
          <p
            className="cursor"
            onClick={() =>
              window.open(
                "https://drive.google.com/file/d/1z3AToLkRuxNA8G67FLZxpfmz_Ro48qoi/view?usp=sharing"
              )
            }
            onMouseEnter={() => ctx.cursorChangeHandler("cursor")}
            onMouseLeave={() => ctx.cursorChangeHandler("")}
          >
            Resume
          </p>
          <span>/</span>
          <p
            className="cursor"
            onMouseEnter={() => ctx.cursorChangeHandler("cursor")}
            onMouseLeave={() => ctx.cursorChangeHandler("")}
          >
            Email
          </p>
        </div>
      </div>
      <div
        className={`${style.headerContent} ${open ? style.open : style.close}`}
        style={{
          background: darkTheme ? "black" : "white",
          // transform: !open ? "translateY(-100%)" : "translateY(0)",
        }}
      >
        <div className={style.mobileLinks}>
          <Link
            onMouseEnter={() => ctx.cursorChangeHandler("cursor")}
            onMouseLeave={() => ctx.cursorChangeHandler("")}
            style={{ color: `${darkTheme ? "rgba(255, 255, 255)" : ""}` }}
            to="/about"
            onClick={() => setOpen(!open)}
          >
            About
          </Link>
          <p
            className="cursor"
            style={{ color: `${darkTheme ? "rgba(255, 255, 255)" : ""}` }}
            onClick={() =>
              window.open(
                "https://drive.google.com/file/d/1z3AToLkRuxNA8G67FLZxpfmz_Ro48qoi/view?usp=sharing"
              )
            }
            onMouseEnter={() => ctx.cursorChangeHandler("cursor")}
            onMouseLeave={() => ctx.cursorChangeHandler("")}
          >
            Resume
          </p>
          <p
            className="cursor"
            style={{ color: `${darkTheme ? "rgba(255, 255, 255)" : ""}` }}
            onMouseEnter={() => ctx.cursorChangeHandler("cursor")}
            onMouseLeave={() => ctx.cursorChangeHandler("")}
          >
            Email
          </p>
        </div>
        <div className={style.interaction}>
          <div
            className={`${style.icon} cursor`}
            onClick={handleThemeChange}
            onMouseEnter={() => ctx.cursorChangeHandler("cursor")}
            onMouseLeave={() => ctx.cursorChangeHandler("")}
          >
            {!darkTheme ? (
              <BiSun
                className="cursor"
                size={24}
                // style={{ marginRight: "12px" }}
              />
            ) : (
              <HiOutlineMoon
                // onClick={handleThemeChange}
                className="cursor"
                size={24}
                // style={{ marginRight: "12px" }}
                color={"#ffffff"}
              />
            )}
          </div>
          <div
            className={`${style.icon} cursor`}
            onMouseEnter={() => ctx.cursorChangeHandler("cursor")}
            onMouseLeave={() => ctx.cursorChangeHandler("")}
          >
            <FiMusic
              className="cursor"
              size={24}
              color={darkTheme ? "#FFFFFF" : ""}
            />
          </div>
        </div>
      </div>
      <Outlet />
      <Footer />
    </div>
  );
};

export default Header;
