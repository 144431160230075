const education = [
  {
    position: "Bachelor’s in Architecture",
    company: "IIT Roorkee",
    duration: "Apr 2018 - Present",
    location: "Roorkee",
    description:
      "Ideated multiple iterations for Cult.fit Home Workouts, Designed new screens for AI Trainer, Class Booking Flows/Gyms Page.",
  },
  {
    position: "Gundecha Education Academy",
    company: null,
    duration: "April 2006 - Apr 2018",
    location: "Mumbai",
    description:
      "Ideated multiple iterations for Cult.fit Home Workouts, Designed new screens for AI Trainer, Class Booking Flows/Gyms Page.",
  },
];

export default education;
