const experience = [
  {
    position: "Product Design Intern",
    company: "Cult.fit",
    duration: "Nov 2021 - March 2022",
    location: "Bengaluru",
    description:
      "Ideated multiple iterations for Cult.fit Home Workouts, Designed new screens for AI Trainer, Class Booking Flows/Gyms Page.",
  },
  {
    position: "UX Design Lead",
    company: "Rephrase.ai",
    duration: "Dec 2020 - Nov 2022 / Bengaluru",
    location: "Bengaluru",
    description:
      "Lead the design for Rephrase.ai's - AI SaaS Web Software that allows users to create AI-Generated Narrative videos with AI Models of their choosing.",
  },
  {
    position: "Product Design Intern",
    company: "Fampay",
    duration: "Jul 2020 - OCT 2020",
    location: "Bengaluru",
    description:
      "Primarily worked on a project currently under Non Disclosure Agreement. Collaborated with Fampay’s Design Team to ship new features and improvements to the App which has over 10M+ users.",
  }, {
    position: "Design Lead",
    company: "Natural Kheti",
    duration: "May 2020 - Nov 2020",
    location: "Gurgaon",
    description:
      "Headed the entire design and collaborated on projects including the entire Branding, E-Commerce Website design and a Farm Management App.",
  },{
    position: "Experience Design Intern",
    company: "HumanX",
    duration: "Apr 2020 - July 2020",
    location: "Mumbai",
    description:
      "Was a part of the design team responsible for the design revamp of the SBI Mutual Funds Website. I worked in collaboration with Product Managers and Design Mentors to redesign the SBI Mutual Funds Website, as well as introducing Mutual Funds to YONO App.",
  },
];

export default experience;
