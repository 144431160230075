import { configureStore } from "@reduxjs/toolkit";
import theme from "./themeSlice";

const store = configureStore({
  reducer: {
    theme
  }
})

export default store;
