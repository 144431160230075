import React from "react";
import style from "./Footer.module.css";
import logo from "../Header/Assets/logo.svg";
import logoW from "../Header/Assets/logo-white.svg";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useContext } from "react";
import { MouseContext } from "../../context/MouseContext";

const Footer = () => {
  const { darkTheme } = useSelector((state) => state.theme);
  const ctx = useContext(MouseContext);
  const navigate = useNavigate();

  return (
    <>
      <div
        className={style.line}
        style={{ borderColor: darkTheme ? "rgba(255, 255, 255, 0.1)" : "" }}
      ></div>
      <div className={style.footer}>
        <img
          src={!darkTheme ? logo : logoW}
          alt=""
          onClick={() => navigate("/")}
          className="cursor"
        />
        <div className={style.links}>
          <Link
            onMouseEnter={() => ctx.cursorChangeHandler("cursor")}
            onMouseLeave={() => ctx.cursorChangeHandler("")}
            style={{ color: `${darkTheme ? "rgba(255, 255, 255, 0.5)" : ""}` }}
            to="/about"
          >
            About
          </Link>
          <span
            style={{ color: `${darkTheme ? "rgba(255, 255, 255, 0.5)" : ""}` }}
          >
            /
          </span>
          <a
            onMouseEnter={() => ctx.cursorChangeHandler("cursor")}
            onMouseLeave={() => ctx.cursorChangeHandler("")}
            href="https://www.linkedin.com/in/shivamjayaswal/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: `${darkTheme ? "rgba(255, 255, 255, 0.5)" : ""}` }}
            className="cursor"
          >
            LinkedIn
          </a>
          <span
            style={{ color: `${darkTheme ? "rgba(255, 255, 255, 0.5)" : ""}` }}
          >
            /
          </span>
          <a
            onMouseEnter={() => ctx.cursorChangeHandler("cursor")}
            onMouseLeave={() => ctx.cursorChangeHandler("")}
            href="https://twitter.com/Shivam_2906"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: `${darkTheme ? "rgba(255, 255, 255, 0.5)" : ""}` }}
          >
            Twitter
          </a>
          <span
            style={{ color: `${darkTheme ? "rgba(255, 255, 255, 0.5)" : ""}` }}
          >
            /
          </span>
          <a
            onMouseEnter={() => ctx.cursorChangeHandler("cursor")}
            onMouseLeave={() => ctx.cursorChangeHandler("")}
            style={{ color: `${darkTheme ? "rgba(255, 255, 255, 0.5)" : ""}` }}
            href="mailto:shivam.2906@gmail.com"
            className="cursor"
          >
            Email
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
