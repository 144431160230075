const achievements = [
  {
    position: "NASA - ANDC Trophy",
    // company: "Cult.fit",
    duration: "Sept 2020",
    // location: "Bengaluru",
    description:
      "Got Shortlisted in top 110 teams amongst 800+ entries across the country in the 63rd edition of ANDC Trophy under NASA (National Association of Students of Architecure).",
  },
  {
    position: "Upglance Portfolio Showcase",
    // company: "Rephrase.ai",
    duration: "Dec 2021",
    // location: "Bengaluru",
    description:
      "Got my design portfolio featured on Upglance’s Portfolio Showcase in the Top 3 Best Design Portfolios, among 120+ folio entries.",
  },
  {
    position: "Product Folks",
    // company: "Fampay",
    duration: "July 2020 - Oct 2020",
    location: "Bengaluru",
    description:
      "Finalists in Product Folks’ May March and May 2022 Teardown Competition. The Problem statement involved - Creating Users' Social Value on the Curious Jr app (March), and Increasing User Activation on Bitclass app (May).",
  },
];

export default achievements;
